import React, { Fragment } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import { styles } from './style';

const session = JSON.parse(localStorage.getItem('session'));

const privileges = session && JSON.parse(session.data.privileges);

const Actions = props => {
  const { classes } = props;

  return (
    <Fragment>
      <Grid container alignItems='center' className={classes.header} spacing={0}>
        <Grid item md={6} xs={12}>
          <Typography variant='subtitle2' className={classes.headerTitle}>
            {props.name.toUpperCase()} ({props.count}){' '}
            {props.subtitle && props.subtitle.toUpperCase()}{' '}
            {props.additionalText && (
              <Fragment>
                <br /> {props.additionalText}
              </Fragment>
            )}
          </Typography>
        </Grid>
        <Grid item className={classes.headerButtons} md={6} xs={false}>
          {props.filter && (
            <Button onClick={props.filter} color='secondary'>
              <CalendarTodayIcon className={classes.spacing} fontSize='small' /> Filtrar
            </Button>
          )}
          {props.create && privileges[props.table] && privileges[props.table].create && (
            <Button onClick={props.handleCustomCreate || props.handleCreate} color='secondary'>
              <AddCircleIcon /> Crear {props.singularName}
            </Button>
          )}
          {(props.print ||
            props.download ||
            (props.redirect.table === props.table && props.redirect.returnTo)) && (
            <IconButton
              aria-label='Más'
              aria-controls='main-menu'
              aria-haspopup='true'
              onClick={props.handleClickMenu}
              className={classes.moreOptions}
            >
              <MoreVertIcon fontSize='small' />
            </IconButton>
          )}
          <Menu
            id='main-menu'
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.handleCloseMenu}
            className={classes.menuOptions}
          >
            {props.print && (
              <MenuItem onClick={props.handleCloseMenu}>
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary='Imprimir' />
              </MenuItem>
            )}
            {props.download && (
              <MenuItem onClick={props.handleCreatePDF}>
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary='Descargar | PDF' />
              </MenuItem>
            )}
            {props.download && (
              <MenuItem onClick={props.handleCreateXLS}>
                <ListItemIcon>
                  <CloudDownloadIcon />
                </ListItemIcon>
                <ListItemText primary='Descargar | XLS' />
              </MenuItem>
            )}
            {props.additionalAction && (
              <MenuItem onClick={props.additionalAction.handle}>
                <ListItemIcon>{props.additionalAction.icon}</ListItemIcon>
                <ListItemText primary={props.additionalAction.title} />
              </MenuItem>
            )}
            {(props.print || props.download) &&
              props.redirect.table === props.table &&
              props.redirect.returnTo && <Divider />}
            {props.redirect.table === props.table && props.redirect.returnTo && (
              <MenuItem onClick={props.handleReturn}>
                <ListItemIcon>
                  <KeyboardReturnIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    props.redirect.returnToLabel
                      ? `Regresar a ${props.redirect.returnToLabel}`
                      : 'Regresar'
                  }
                />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
      {/*<div className={ !props.additionalText ? classes.toolbar : classes.alternativeToolbar} />*/}
    </Fragment>
  );
};

export default withStyles(styles)(Actions);
