import React, { useEffect, Fragment, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import BillingIcon from '@material-ui/icons/AssignmentLate';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import { recordsActions, openFormDialog, editValueAction } from '../../_actions';
import { countriesSelectField, statesSelectField } from '../../_helpers';

import BillingInformation from './billingInformation';

const _session = JSON.parse(localStorage.getItem('session'));

const branchId = _session ? _session.data.branchId : 0;
const city = _session ? _session.data.city : 0;
const stateId = _session ? _session.data.stateId : 0;
const countryId = _session ? _session.data.countryId : 0;

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Customer = ({ getRecords, openFormDialog, editValueAction, ...props }) => {
  useEffect(() => {
    editValueAction({ branchId, city, stateId, countryId });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const states = useCallback(statesSelectField(props.countryId), [props.countryId]);

  useEffect(() => {
    getRecords({ table: 'groups' });
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords]);
  useEffect(() => {}, [getRecords, editValueAction]);

  const employees = useMemo(
    () =>
      props.employees.count > 0
        ? props.employees.rows.map(el => ({
            value: el.id,
            label: `${el.firstName} ${el.lastName}`,
          }))
        : [],
    [props.employees],
  );

  const groups = useMemo(() => {
    return props.groups.count > 0
      ? props.groups.rows.map(({ id, name }) => ({ key: id, value: name }))
      : [];
  }, [props.groups]);

  const handleOpenBilling = e => {
    const { BillingInformation, id } = JSON.parse(e.currentTarget.dataset.row);
    const Country = BillingInformation ? BillingInformation['Country'] : null;
    const State = BillingInformation ? BillingInformation['State'] : null;
    const billingCountryId = Country ? Country['id'] : {};
    const billingStateId = State ? State['id'] : null;
    editValueAction({ billingCountryId, billingStateId, ...BillingInformation, customerId: id });
    openFormDialog('Billing');
  };
  const customers = useMemo(
    () =>
      props.customers.count > 0
        ? props.customers.rows.map(el => ({
            ID: {
              show: true,
              value: el.id,
              data: {
                phone1Id:
                  el.CustomerPhones && el.CustomerPhones.length > 0 && el.CustomerPhones[0].id,
                phone1:
                  el.CustomerPhones && el.CustomerPhones.length > 0 && el.CustomerPhones[0].phone,
                addressLine1: el.CustomerAddress && el.CustomerAddress.addressLine1,
                addressLine2: el.CustomerAddress && el.CustomerAddress.addressLine2,
                city: el.CustomerAddress && el.CustomerAddress.city,
                zipCode: el.CustomerAddress && el.CustomerAddress.zipCode,
                reference: el.CustomerAddress && el.CustomerAddress.zipCode,
                countryId: el.CustomerAddress && el.CustomerAddress.countryId,
                stateId: el.CustomerAddress && el.CustomerAddress.stateId,
                groupId: el.Group && el.Group.id,
                phone2Id: el.CustomerPhones && el.CustomerPhones[1] && el.CustomerPhones[1].id,
                phone2: el.CustomerPhones && el.CustomerPhones[1] && el.CustomerPhones[1].phone,
                ...el,
              },
            },
            '#': {
              value: el.code,
            },
            Nombre: {
              value: el.firstName + ' ' + el.lastName,
              filter: true,
              _sort: 'text',
            },
            'Razón Social': {
              value: el.BillingInformation ? el.BillingInformation.businessName : '',
              filter: true,
              _sort: 'text',
            },

            RFC: {
              value: el.BillingInformation ? el.BillingInformation.identifier : '',
              filter: true,
              _sort: 'text',
            },
            'Dscto.': {
              value: el.discountRange + '%',
              align: 'center',
            },
            Domicilio: {
              value:
                el.CustomerAddress &&
                el.CustomerAddress.addressLine1 +
                  (el.CustomerAddress.addressLine2 !== ''
                    ? ', ' + el.CustomerAddress.addressLine2
                    : '') +
                  (el.CustomerAddress.city !== '' ? ', ' + el.CustomerAddress.city : '') +
                  (el.CustomerAddress.zipCode !== '' ? ', ' + el.CustomerAddress.zipCode : '') +
                  (el.CustomerAddress.State ? ', ' + el.CustomerAddress.State.name : ''),
            },
            Teléfono: {
              value:
                el.CustomerPhones && el.CustomerPhones.length > 0 && el.CustomerPhones[0].phone,
              filter: true,
            },
          }))
        : [],
    [props.customers],
  );

  return (
    <Fragment>
      <Module
        table='customers'
        name='clientes'
        records={customers}
        singularName='cliente'
        filter={false}
        title='Crear Cliente'
        download={true}
        actions={
          privileges.customers &&
          privileges.customers.billingInfo && [
            {
              icon: <BillingIcon fontSize='small' />,
              label: 'Datos de Facturación',
              handle: handleOpenBilling,
            },
          ]
        }
      >
        <TextField
          icon='account_circle'
          fields={[
            {
              id: 'firstName',
              label: 'Nombre',
              autoFocus: true,
            } /*,{
            id: "lastName",
            label: "Apellidos"
          }*/,
          ]}
        />
        <SelectField
          fields={[
            {
              id: 'groupId',
              label: 'Grupo',
              options: groups,
            },
          ]}
        />
        <TextField
          icon='card_giftcard'
          fields={[
            {
              id: 'discountRange',
              label: 'Dscto. (Venta)',
              required: false,
            },
            {
              id: 'rentDiscountRange',
              label: 'Dscto. (Renta)',
              required: false,
            },
          ]}
        />
        <Autocomplete icon='person' label='Vendedor' _id='employeeId' options={employees} />
        <TextField
          icon='phone'
          fields={[
            {
              id: 'phone1',
              label: 'Teléfono 1',
              required: false,
            },
          ]}
        />
        <TextField
          fields={[
            {
              id: 'phone2',
              label: 'Teléfono 2',
              required: false,
            },
          ]}
        />
        <TextField
          icon='room'
          fields={[
            {
              id: 'addressLine1',
              label: 'Calle y Número',
              multiline: true,
            },
            {
              id: 'addressLine2',
              label: 'Colonia',
              multiline: true,
              required: false,
            },
          ]}
        />
        <TextField
          fields={[
            {
              id: 'city',
              label: 'Ciudad',
              required: false,
            },
            {
              id: 'zipCode',
              label: 'Código Postal',
              required: false,
            },
          ]}
        />
        <SelectField
          fields={[
            {
              id: 'countryId',
              label: 'País',
              options: countriesSelectField,
              required: false,
            },
          ]}
        />
        <SelectField
          fields={[
            {
              id: 'stateId',
              label: 'Estado',
              options: states,
              required: false,
            },
          ]}
        />
        <TextField
          fields={[
            {
              id: 'reference',
              label: 'Referencia',
              multiline: true,
              required: false,
            },
          ]}
        />
        <SelectField
          fields={[
            {
              id: 'iva',
              label: 'IVA',
              options: [
                { key: 16, value: '16%' },
                { key: 8, value: '8%' },
              ],
            },
          ]}
        />
      </Module>
      <BillingInformation countries={countriesSelectField} />
    </Fragment>
  );
};

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  groups: state.records.groups || defaultTableValue,
  branch: state.records.branch || defaultTableValue,
  customers: state.records.customers || defaultTableValue,
  countryId: state.field.value.countryId || 0,
  employees: state.records.employees || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: value => dispatch(editValueAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
